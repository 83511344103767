import {
  BzAddress,
  BzDateFns,
  CompanyGuid,
  EquipmentType,
  LocationLookupCandidate,
  OnlineBookingServiceTypeDescriptions,
  phoneUtils,
  ThisShouldNeverHappenError,
} from '@breezy/shared'
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, message } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { SectionedContent } from '../../adam-components/SectionedCard/SectionedContent'
import { StepProgressIndicators } from '../../adam-components/StepProgressIndicators/StepProgressIndicators'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { OnlineBookingAvailabilitySubForm } from './OnlineBookingAvailabilitySubForm'
import { OnlineBookingContactSubForm } from './OnlineBookingContactSubForm'
import { OnlineBookingRequestDetailsSubForm } from './OnlineBookingRequestDetailsSubForm'
import {
  AvailabilitySubFormData,
  AvailabilitySubFormSchema,
  ContactSubFormData,
  ContactSubFormSchema,
  RequestDetailsSubFormData,
  RequestDetailsSubFormSchema,
} from './utils'

type JobRequestFormProps = {
  companyGuid: CompanyGuid
  hiddenEquipmentTypes: EquipmentType[]
  onJobRequestSuccessfullySubmitted: () => void
}

const FORM_STAGES = [
  {
    name: 'Request Details',
  },
  {
    name: 'Availability',
  },
  {
    name: 'Contact Info',
  },
  {
    name: 'Review Request',
  },
] as const

type FORM_STAGE_NAMES = (typeof FORM_STAGES)[number]['name']

const DEFAULT_STEP = 0

export const OnlineBookingForm = React.memo<JobRequestFormProps>(
  ({
    companyGuid,
    hiddenEquipmentTypes,
    onJobRequestSuccessfullySubmitted,
  }) => {
    const isMobile = useIsMobile()
    const [currentStep, setCurrentStep] = useState<number>(DEFAULT_STEP)

    const currentFormStageName = useMemo<FORM_STAGE_NAMES>(
      () => FORM_STAGES[currentStep].name,
      [currentStep],
    )

    const requestDetailsSubForm = useForm<RequestDetailsSubFormData>({
      resolver: zodResolver(RequestDetailsSubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const availabilitySubForm = useForm<AvailabilitySubFormData>({
      resolver: zodResolver(AvailabilitySubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const contactSubForm = useForm<ContactSubFormData>({
      resolver: zodResolver(ContactSubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const [submitRequestDetailsElement, triggerRequestDetailsSubmit] =
      useReactHookFormSubmit()
    const [submitAvailabilityElement, triggerAvailabilitySubmit] =
      useReactHookFormSubmit()
    const [submitContactElement, triggerContactSubmit] =
      useReactHookFormSubmit()

    const moveToNextStep = useCallback(() => {
      setCurrentStep(currStep => currStep + 1)
    }, [setCurrentStep])

    const moveToPreviousStep = useCallback(() => {
      setCurrentStep(currStep => currStep - 1)
    }, [setCurrentStep])

    const onRequestDetailsSubmit = useCallback(
      (data: RequestDetailsSubFormData) => {
        moveToNextStep()
      },
      [moveToNextStep],
    )

    const onAvailabilitySubmit = useCallback(
      (data: AvailabilitySubFormData) => {
        moveToNextStep()
      },
      [moveToNextStep],
    )

    const [locationLookupCandidateStatus, setLocationLookupCandidateStatus] =
      useState<'idle' | 'fetching' | 'not-found'>('idle')

    const trpcClient = trpc.useContext().client

    const [locationLookupCandidate, setLocationLookupCandidate] = useState<
      LocationLookupCandidate | undefined
    >(undefined)

    const onContactSubmit = useCallback(
      async (data: ContactSubFormData) => {
        if (data.existingCustomer) {
          setLocationLookupCandidateStatus('fetching')

          try {
            const result = await trpcClient.location[
              'unauth:lookup-location'
            ].query({
              companyGuid,
              addressLine1: data.serviceAddressLine1,
              addressLine2: data.serviceAddressLine2,
              zipCode: data.serviceAddressZipCode,
              stateAbbreviation: data.serviceAddressStateAbbreviation,
            })
            setLocationLookupCandidateStatus(result ? 'idle' : 'not-found')
            setLocationLookupCandidate(result)
            if (result) {
              moveToNextStep()
            } else {
              contactSubForm.setError('root.addressNotFound', {
                message: 'Address not found',
              })
              setLocationLookupCandidateStatus('not-found')
              setLocationLookupCandidate(undefined)
            }
            return true
          } catch (e) {
            console.error(`There was an error fetching the lookup-location`, e)
            setLocationLookupCandidateStatus('not-found')
            setLocationLookupCandidate(undefined)
            contactSubForm.setError('root.addressNotFound', {
              message: 'Address not found',
            })
          }
        } else {
          moveToNextStep()
        }
      },
      [companyGuid, contactSubForm, moveToNextStep, trpcClient.location],
    )

    const jobLeadsCreateMutation =
      trpc.jobLeads['unauth:job-leads:create'].useMutation()

    const submitJobRequest = useCallback(async () => {
      const contactFormValues = contactSubForm.getValues()
      const requestDetailsFormValues = requestDetailsSubForm.getValues()
      const availabilityFormValues = availabilitySubForm.getValues()

      const availabilities: string[] = [
        `${BzDateFns.localDateToFriendlyDateString(
          availabilityFormValues.preferredAvailabilityDate,
        )} (${availabilityFormValues.preferredAvailabilityTimeWindows.join(
          ', ',
        )})`,
      ]

      if (availabilityFormValues.backupAvailabilityDate) {
        if (
          availabilityFormValues.backupAvailabilityTimeWindows &&
          availabilityFormValues.backupAvailabilityTimeWindows.length
        ) {
          availabilities.push(
            `${BzDateFns.localDateToFriendlyDateString(
              availabilityFormValues.backupAvailabilityDate,
            )} (${availabilityFormValues.backupAvailabilityTimeWindows.join(
              ', ',
            )})`,
          )
        } else {
          availabilities.push(
            BzDateFns.localDateToFriendlyDateString(
              availabilityFormValues.backupAvailabilityDate,
            ),
          )
        }
      }

      try {
        if (locationLookupCandidate) {
          await jobLeadsCreateMutation.mutateAsync({
            companyGuid,
            preExistingLocationGuid: locationLookupCandidate.locationGuid,
            requestType: requestDetailsFormValues.requestType,
            equipmentTypes: requestDetailsFormValues.equipmentTypes,
            estimateType: requestDetailsFormValues.estimateType,
            appointmentAvailabilities: availabilities,
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })
        } else if (contactFormValues.newContact) {
          await jobLeadsCreateMutation.mutateAsync({
            companyGuid,
            contactFirstName: contactFormValues.newContact.firstName,
            contactLastName: contactFormValues.newContact.lastName,
            contactPhoneNumber: contactFormValues.newContact.phoneNumber,
            contactEmailAddress:
              contactFormValues.newContact.primaryEmailAddress,
            serviceAddressLine1: contactFormValues.serviceAddressLine1,
            serviceAddressLine2: contactFormValues.serviceAddressLine2,
            serviceAddressCity: contactFormValues.serviceAddressCity,
            serviceAddressStateAbbreviation:
              contactFormValues.serviceAddressStateAbbreviation,
            serviceAddressZipCode: contactFormValues.serviceAddressZipCode,
            requestType: requestDetailsFormValues.requestType,
            equipmentTypes: requestDetailsFormValues.equipmentTypes,
            estimateType: requestDetailsFormValues.estimateType,
            appointmentAvailabilities: availabilities,
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })
        }

        onJobRequestSuccessfullySubmitted()
      } catch (e) {
        console.error(`There was an error creating a job lead`, e)
        message.error(
          `There was an error requesting the job. Please try again in a few minutes.`,
        )
      }
    }, [
      availabilitySubForm,
      companyGuid,
      contactSubForm,
      jobLeadsCreateMutation,
      locationLookupCandidate,
      onJobRequestSuccessfullySubmitted,
      requestDetailsSubForm,
    ])

    const isBackButtonEnabled = useMemo(() => {
      return (
        currentStep !== 0 &&
        locationLookupCandidateStatus !== 'fetching' &&
        !jobLeadsCreateMutation.isLoading
      )
    }, [
      currentStep,
      jobLeadsCreateMutation.isLoading,
      locationLookupCandidateStatus,
    ])

    const onPrimaryCTAButtonClicked = useCallback(() => {
      switch (currentFormStageName) {
        case 'Request Details':
          triggerRequestDetailsSubmit()
          break
        case 'Availability':
          triggerAvailabilitySubmit()
          break
        case 'Contact Info':
          triggerContactSubmit()
          break
        case 'Review Request':
          submitJobRequest()
          break
        default:
          throw new ThisShouldNeverHappenError('Impossible stage')
      }
    }, [
      currentFormStageName,
      submitJobRequest,
      triggerAvailabilitySubmit,
      triggerContactSubmit,
      triggerRequestDetailsSubmit,
    ])

    const onClickCompleteContactForm = useCallback(() => {
      moveToPreviousStep()
      setLocationLookupCandidate(undefined)
      contactSubForm.setValue('existingCustomer', false)
      contactSubForm.setValue('newContact', undefined)
      contactSubForm.setValue('serviceAddressLine1', '')
      contactSubForm.setValue('serviceAddressLine2', '')
      contactSubForm.setValue('serviceAddressCity', '')
      contactSubForm.setValue('serviceAddressStateAbbreviation', '')
      contactSubForm.setValue('serviceAddressZipCode', '')
    }, [moveToPreviousStep, contactSubForm])

    const reviewRequestData = (() => {
      if (currentFormStageName !== 'Review Request') {
        return undefined
      }

      const newContactData = contactSubForm.getValues().newContact
      if (locationLookupCandidate) {
        return {
          name: `${locationLookupCandidate.firstName} ${locationLookupCandidate.lastNameAbbrev}`,
          address: locationLookupCandidate.formattedAddress,
          phoneNumber: locationLookupCandidate.phoneNumberRedacted,
          emailAddress: locationLookupCandidate.emailAddressRedacted,
        }
      } else if (newContactData) {
        const address = BzAddress.create({
          line1: contactSubForm.getValues().serviceAddressLine1,
          line2: contactSubForm.getValues().serviceAddressLine2,
          city: contactSubForm.getValues().serviceAddressCity,
          stateAbbreviation:
            contactSubForm.getValues().serviceAddressStateAbbreviation,
          zipCode: contactSubForm.getValues().serviceAddressZipCode,
        })
        return {
          name: `${newContactData.firstName} ${newContactData.lastName}`,
          address: address.formatSingleLine(),
          phoneNumber: newContactData.phoneNumber,
          emailAddress: newContactData.primaryEmailAddress,
        }
      }

      return undefined
    })()

    const goBack = useCallback(() => {
      setLocationLookupCandidate(undefined)
      contactSubForm.clearErrors()
      moveToPreviousStep()
    }, [contactSubForm, moveToPreviousStep, setLocationLookupCandidate])

    return (
      <>
        <OnsitePageSection padding="large-mobile">
          <div className="flex flex-col">
            <div className="break-words text-[20px] font-[600] leading-[28px] text-[rgba(0,0,0,0.88)] text-bz-text">
              {currentFormStageName}
            </div>

            {currentFormStageName === 'Request Details' && (
              <FormProvider {...requestDetailsSubForm}>
                <Form
                  requiredMark="optional"
                  className="mt-[12px] flex min-h-0 flex-1 flex-col"
                  layout="vertical"
                  onSubmitCapture={requestDetailsSubForm.handleSubmit(
                    onRequestDetailsSubmit,
                  )}
                >
                  <OnlineBookingRequestDetailsSubForm
                    hiddenEquipmentTypes={hiddenEquipmentTypes}
                  />
                  {submitRequestDetailsElement}
                </Form>
              </FormProvider>
            )}

            {currentFormStageName === 'Availability' && (
              <FormProvider {...availabilitySubForm}>
                <Form
                  className="mt-[12px] flex min-h-0 flex-1 flex-col"
                  layout="vertical"
                  onSubmitCapture={availabilitySubForm.handleSubmit(
                    onAvailabilitySubmit,
                  )}
                  requiredMark="optional"
                >
                  <OnlineBookingAvailabilitySubForm />
                  {submitAvailabilityElement}
                </Form>
              </FormProvider>
            )}

            {currentFormStageName === 'Contact Info' && (
              <FormProvider {...contactSubForm}>
                <Form
                  className="mt-[12px] flex min-h-0 flex-1 flex-col"
                  layout="vertical"
                  onSubmitCapture={contactSubForm.handleSubmit(onContactSubmit)}
                  requiredMark={isMobile ? undefined : 'optional'}
                >
                  <OnlineBookingContactSubForm />
                  {submitContactElement}
                </Form>
              </FormProvider>
            )}

            {currentFormStageName === 'Review Request' && reviewRequestData && (
              <SectionedContent
                dashed
                sections={[
                  {
                    verticalPaddingClassName: 'pt-3 pb-6',
                    content: (
                      <div>
                        <div className="flex flex-col gap-1">
                          {locationLookupCandidate ? (
                            <div>
                              Great, we've found your account information:
                            </div>
                          ) : (
                            <div>
                              Please review your contact information below:
                            </div>
                          )}
                          <div className="flex gap-3">
                            <div>
                              <FontAwesomeIcon
                                size="sm"
                                icon={faUser}
                                className="text-bz-gray-700"
                              />
                            </div>
                            <div className="font-semibold text-[#000000E0]">
                              {reviewRequestData.name}
                            </div>
                          </div>
                          {reviewRequestData.phoneNumber && (
                            <div className="flex gap-3">
                              <div>
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={faPhone}
                                  className="text-bz-gray-700"
                                />
                              </div>
                              <div className="font-semibold text-[#000000E0]">
                                {phoneUtils.tryFormatWithFallback(
                                  reviewRequestData.phoneNumber,
                                  reviewRequestData.phoneNumber,
                                )}
                              </div>
                            </div>
                          )}

                          {reviewRequestData.emailAddress && (
                            <div className="flex gap-3">
                              <div>
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={faEnvelope}
                                  className="text-bz-gray-700"
                                />
                              </div>
                              <div className="font-semibold text-[#000000E0]">
                                {reviewRequestData.emailAddress}
                              </div>
                            </div>
                          )}

                          <div className="flex gap-3">
                            <div>
                              <FontAwesomeIcon
                                size="sm"
                                icon={faLocationDot}
                                className="text-bz-gray-700"
                              />
                            </div>
                            <div className="font-semibold text-[#000000E0]">
                              {reviewRequestData.address}
                            </div>
                          </div>
                        </div>
                        {locationLookupCandidate && (
                          <div className="mt-4">
                            Not you? Complete the{' '}
                            <span
                              className="cursor-pointer text-bz-primary"
                              onClick={onClickCompleteContactForm}
                            >
                              contact form
                            </span>{' '}
                            instead.
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    verticalPaddingClassName: 'pt-6 pb-0',
                    content: (
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <div className="font-semibold text-[#1f1f1f] ">
                            Request Type
                          </div>
                          <div className="text-[#1f1f1f] ">
                            {
                              OnlineBookingServiceTypeDescriptions[
                                requestDetailsSubForm.getValues().requestType
                              ]
                            }
                          </div>
                        </div>
                        {requestDetailsSubForm.getValues().requestDetails && (
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold text-[#1f1f1f] ">
                              Request Details
                            </div>
                            <div className="text-[#1f1f1f] ">
                              {requestDetailsSubForm.getValues().requestDetails}
                            </div>
                          </div>
                        )}

                        <div className="flex flex-col gap-1">
                          <div className="font-semibold text-[#1f1f1f] ">
                            Preferred Date #1
                          </div>
                          <div className="text-[#1f1f1f] ">
                            {BzDateFns.localDateToFriendlyDateString(
                              availabilitySubForm.getValues()
                                .preferredAvailabilityDate,
                            )}{' '}
                            {'('}
                            {availabilitySubForm
                              .getValues()
                              .preferredAvailabilityTimeWindows.join(', ')}
                            {')'}
                          </div>
                        </div>

                        {availabilitySubForm.getValues()
                          .backupAvailabilityDate && (
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold text-[#1f1f1f] ">
                              Preferred Date #2
                            </div>
                            <div className="text-[#1f1f1f] ">
                              {BzDateFns.localDateToFriendlyDateString(
                                availabilitySubForm.getValues()
                                  .backupAvailabilityDate!,
                              )}
                              {availabilitySubForm.getValues()
                                .backupAvailabilityTimeWindows && (
                                <>
                                  {' '}
                                  {'('}
                                  {availabilitySubForm
                                    .getValues()
                                    .backupAvailabilityTimeWindows!.join(', ')}
                                  {')'}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>
        </OnsitePageSection>

        <OnsitePageSection padding="large-mobile">
          <div className="flex items-center justify-between">
            <StepProgressIndicators
              currentStep={currentStep}
              totalSteps={FORM_STAGES.length}
              onStepIndicatorClicked={(step: number) => setCurrentStep(step)}
            />
            <div className="flex flex-row gap-[12px]">
              <Button
                disabled={!isBackButtonEnabled}
                onClick={goBack}
                size="large"
              >
                Back
              </Button>
              <Button
                className="min-w-[140px]"
                block
                size="large"
                type="primary"
                onClick={onPrimaryCTAButtonClicked}
                loading={
                  locationLookupCandidateStatus === 'fetching' ||
                  jobLeadsCreateMutation.isLoading
                }
              >
                {currentFormStageName === 'Review Request' ? 'Submit' : 'Next'}
              </Button>
            </div>
          </div>
        </OnsitePageSection>
      </>
    )
  },
)
